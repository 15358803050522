import React from "react"
import { Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import Layout from "../components/layout"

export default ({ data }) => (
  <Layout>
    <h1>Welcome!</h1>
    <p>Thanks for visiting.</p>
    {/* <p>This is a random image:</p>
    <img src="https://source.unsplash.com/random/400x200" alt="Random"></img> */}
    <h2>{data.allMarkdownRemark.totalCount} Post(s)</h2>
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <div key={node.id}>
        <Link to={node.fields.slug}>
          <h3 style={{ marginBottom: `${rhythm(1 / 4)}` }}>
            {node.frontmatter.title}{" "}
            <span style={{ color: `#bbb` }}>— {node.frontmatter.date}</span>
          </h3>
        </Link>
        <p>{node.excerpt}</p>
      </div>
    ))}
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
